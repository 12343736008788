import { AccountsApi, AuthApi, Configuration } from "../api-backend";
import { backendConfig } from "../api-backend/config";

export class AuthorizeService {

  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  async isAuthenticated() {
    return !!(await this.getAccessToken());

    /*const user = await this.getUser();
    const tokens = user && user.getTokens();

    var hasValidToken = false;
    if(!!tokens) {
      const now = Date.now();
      hasValidToken = 
        (now < (tokens.updateTime + tokens.accessToken.expiresIn * 1000)) ||
        (now < (tokens.updateTime + tokens.refreshToken.expiresIn * 1000)) 

    }
    return hasValidToken;*/
  }

  
  async getUser() {
    var self = this === undefined ? authService : this;
    if(self._user == null) self._user = new User();

    return self._user;
  }


  async getAccessToken() {
    const thisObject = (this === undefined ? authService : this);
    const user = await thisObject.getUser();
    var tokens = user && user.getTokens();

    // check if access token is expired or expires soon and refresh token is still valid
    if(!!tokens) {
      const now = Date.now();
      const delta = 60; //s

      // check accessToken for expiration
      if(now > (tokens.updateTime + (tokens.accessToken.expiresIn - delta) * 1000)) {
        // access token has expired

        if(now < (tokens.updateTime + tokens.refreshToken.expiresIn * 1000)) {
          // refresh token is still valid
          var api = new AuthApi(new Configuration(backendConfig));
          try {
            var token = await api.refreshAccessToken({"authRefreshAccessTokenRequest" : {refreshToken: tokens.refreshToken.token, oldAccessToken: tokens.accessToken.token}});
            user.setTokens(token);
          }
          catch(e) {
            if (e instanceof Response && e.status === 400) {
              // token exchange did not work => log out the user
              await thisObject.signOut()
              tokens = null;
            }
          }
    
        } else {
          // both tokens are expired => reset the tokens to null
          await thisObject.signOut()
          tokens = null;

        }
      }
    }

    // now we either have no token, or an up to date. accessToken
    return tokens && tokens.accessToken.token;
  }



  async register(username, email, password, remember) {

    // need to build my api on my own since can not import ApiBackend.ts to avoid interdependencies
    try {
      var api = new AccountsApi(new Configuration(backendConfig));
      await api.create({"accountCreateRequest" : {email: email, password: password, userName: username}});

    } catch(e) {

      // get the json response of the fault
      var f = await e.body.getReader().read();
      var arr = f.value;
      var i, str = '';
      for (i = 0; i < arr.length; i++) {
          str += '%' + ('0' + arr[i].toString(16)).slice(-2);
      }
      str = JSON.parse(decodeURIComponent(str));
      return { success: false, fault : str };
    }

    return this.signIn(username, password, remember);
  }
  

  
  async signIn(username, password, remember) {
    var success = null;

    // need to build my api on my own since can not import ApiBackend.ts to avoid interdependencies
    try {
      var api = new AuthApi(new Configuration(backendConfig));
      var token = await api.login({"authLoginRequest" : {emailOrUserName : username, password: password}});
      (await this.getUser()).setTokens(token, remember);

      success = true;

    } catch(e) {

      // get the json response of the fault
      var f = await e.body.getReader().read();
      var arr = f.value;
      var i, str = '';
      for (i = 0; i < arr.length; i++) {
          str += '%' + ('0' + arr[i].toString(16)).slice(-2);
      }
      str = JSON.parse(decodeURIComponent(str));
      success = false;
    }
    
    this.notifySubscribers();
    return { success: success, fault : str };
  }

  
  async signOut() {
    (await this.getUser()).clearTokens();
    this.notifySubscribers();
  }


  // #region Callbacks
  subscribe(callback) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }
  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
      .filter(element => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }
  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }
  // #endregion
}

class User {
  setTokens(tokens, rememberMe) {
    if(rememberMe === undefined) rememberMe = localStorage.getItem("Reisewarnungen_rememberMe"); // if not given, try to read it from the localStorrage;
    if(rememberMe === undefined) rememberMe = false; // if it can not be read from the storrage, put the values to the session storrage

    // store the info where we stored the tokens in the localStorage
    localStorage.setItem("Reisewarnungen_rememberMe", rememberMe);

    tokens = {updateTime : Date.now(), ...tokens};

    // store the tokens depending on the rememberMe flat
    (rememberMe ? localStorage : sessionStorage).setItem("Reisewarnungen_tokens", JSON.stringify(tokens));
  }

  getTokens() {
    // get where we did store the tokens
    var rememberMe = localStorage.getItem("Reisewarnungen_rememberMe");

    // get the tokens depending on the rememberMe flat
    return JSON.parse((rememberMe ? localStorage : sessionStorage).getItem("Reisewarnungen_tokens"));
  }

  clearTokens() {
    localStorage.clear();
    sessionStorage.clear();
  }
}


const authService = new AuthorizeService();

export default authService;
