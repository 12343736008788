import React, { Component } from 'react';

export class Home extends Component {
  render () {
    return (
      <div>
        <h1>Reisewarnungen Auswärtiges Amt</h1>
        <iframe title="Reisewarnungen Auswärtiges Amt" aria-label="Map" id="datawrapper-chart-T3v9a" src="https://datawrapper.dwcdn.net/T3v9a/4/" scrolling="no" frameBorder="0" style={{"border": "none"}} width="100%" height="650" data-external="1"></iframe>

      </div>
    );
  }
}
